<div class="container">
    <div style="height: calc(100vh - 91px)" *ngIf="service.productDetails.BigFiles?.length==0"></div>
    <div class="row">
        <div class="col-sm-8">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">{{service.getResource("home")}}</a></li>
                    <li class="breadcrumb-item"><a href="/kataloglar">{{service.getResource("catalogs")}}</a></li>
                    <li class="breadcrumb-item"><a href="/modeller/{{service.catalogName}}">{{service.productDetails.CatalogName}} <span
                            >{{service.getResource("title_models")}}</span
                            ></a></li>
                    <li class="breadcrumb-item"><a>{{service.productDetails.ProductName}}</a></li>
                </ol>
            </nav>
        </div>
        <div class="col-sm-4">
            <div style="margin-top: 30px; float: right">
                <ngb-rating [max]="5"
                            [(ngModel)]="service.productDetails.AverageRate"
                            (click)="updateRate()"
                            [(rate)]="service.productDetails.AverageRate" [readonly]="false"></ngb-rating>
                <br>
                <i class="far fa-hand-pointer"></i> {{service.productDetails.RateCount}}
            </div>
        </div>
        <div class="col-sm-6">
            <ng-image-slider *ngIf="service.productDetails.BigFiles!=null"
                             [images]="service.sliderImages"
                             [infinite]="false"
                             [autoSlide]="1"
                             slideImage="1"
                             [imageSize]="{width: '400px', height: '400px'}"
                             #nav></ng-image-slider>
        </div>
        <div class="col-sm-6">
            <table style="width: 100%">
                <tr>
                    <td><b style="color: #1b1e21!important;">{{service.getResource("model_code")}}</b></td>
                    <td>{{service.productDetails.ProductCode}}</td>
                </tr>
                <tr>
                    <td><b style="color: #1b1e21!important;">{{service.getResource("product_group_label")}}</b></td>
                    <td>{{service.productDetails.ProductGroupName}}</td>
                </tr>
                <tr>
                    <td><b style="color: #1b1e21!important;">{{service.getResource("brand_label")}}</b></td>
                    <td>{{service.productDetails.BrandName}}</td>
                </tr>
                <tr>
                    <td><b style="color: #1b1e21!important;">{{service.getResource("catalog_label")}}</b></td>
                    <td>{{service.productDetails.CatalogName}}</td>
                </tr>
                <tr>
                    <td><b style="color: #1b1e21!important;">{{service.getResource("origin_label")}}</b></td>
                    <td>{{service.productDetails.OriginName}}</td>
                </tr>
                <tr>
                    <td><b style="color: #1b1e21!important;">{{service.getResource("base_label")}}</b></td>
                    <td>{{service.productDetails.BaseName}}</td>
                </tr>
                <tr>
                    <td><b style="color: #1b1e21!important;">{{service.getResource("weight_label")}}</b></td>
                    <td>{{service.productDetails.Weight}}</td>
                </tr>
                <tr>
                    <td><b style="color: #1b1e21!important;">{{service.getResource("width_label")}}</b></td>
                    <td>{{service.productDetails.Width}}</td>
                </tr>
                <tr>
                    <td><b style="color: #1b1e21!important;">{{service.getResource("height_label")}}</b></td>
                    <td>{{service.productDetails.Height}}</td>
                </tr>
                <tr *ngFor="let prop of service.properties">
                    <td><b style="color: #1b1e21!important;">{{prop.PropertyName}}</b></td>
                    <td>{{prop.PropertyValue}}</td>
                </tr>
                <tr *ngIf="service.productDetails.Description!=null">
                    <td><b style="color: #1b1e21!important;">{{service.getResource("description")}}</b></td>
                    <td>{{service.productDetails.Description}}</td>
                </tr>
            </table>
        </div>
        <div class="col-sm-12">
            <h6>{{service.getResource("related_products")}}</h6>

            <ng-image-slider *ngIf="service.productDetails.BigFiles!=null"
                             style="cursor: pointer"
                             [images]="service.relatedProduct"
                             [infinite]="false"
                             [imagePopup]="false"
                             [autoSlide]="1"
                             (imageClick)="onOpenImage($event)"
                             slideImage="1"
                             [imageSize]="{width: '200px', height: '200px'}"
                             #nav2></ng-image-slider>
        </div>
        <br>
        <br>
        <br>
        <div class="col-sm-8 offset-sm-2 text-center"
             *ngIf="service.productDetails.Comments==null || service.productDetails.Comments?.length==0" style="">
            <span>{{service.getResource("there_is_no_comments")}}</span>
        </div>
        <br>
        <br>
        <div class="col-sm-8 offset-sm-2 ng-scope" *ngFor="let comment of service.productDetails.Comments" style="">
            <div class="row" style="margin: 5px 0; border-bottom: 1px solid #ddd">
                <div class="col-sm-12" style="padding: 0"><i>{{comment.CreatedDate | date:'dd/MM/yyyy'}}</i></div>
                <div class="col-sm-12" style="padding: 0">
                    <b style="color: #111" class="ng-binding">{{comment.Comment}}</b>
                </div>
            </div>
        </div>
        <div class="col-sm-8 offset-sm-2">
            <span></span>

            <p *ngIf="service.addedCommentSuccessFully" class="text-success"><i class="fa fa-check"></i> <span
            >{{service.getResource("comment_successfully_sent")}}</span></p>


            <form novalidate="" style="margin: 15px 0" class="ng-pristine ng-invalid ng-invalid-required"
                  *ngIf="!service.addedCommentSuccessFully">
                <div class="form-group">
                    <label>{{service.getResource("name")}}</label>
                    <input name="name" class="form-control" [(ngModel)]="formComment.Title"
                           [ngClass]="{'error-border': (formComment.Title==null || formComment.Title=='') && submitted}">
                    <span class="help-block has-error error-msg"
                          *ngIf="formComment.Title==null && submitted">{{service.getResource("required")}}</span>
                </div>
                <div class="form-group">
                    <label>{{service.getResource("phone_number")}}</label>
                    <input name="phoneNumber" class="form-control" [(ngModel)]="formComment.PhoneNumber"
                           [ngClass]="{'error-border': (formComment.PhoneNumber==null || formComment.PhoneNumber =='')  && submitted}">
                    <span class="help-block has-error error-msg"
                          *ngIf="formComment.PhoneNumber==null && submitted">{{service.getResource("required")}}</span>
                </div>
                <div class="form-group">
                    <label>{{service.getResource("email")}}</label>
                    <input name="email" class="form-control" [(ngModel)]="formComment.Email"
                           [ngClass]="{'error-border': (formComment.Email==null || formComment.Email=='')  && submitted}">
                    <span class="help-block has-error error-msg" translate="required"
                          *ngIf="formComment.Email==null && submitted"></span>
                </div>
                <div class="form-group">
                    <label  for="commentTextArea">{{service.getResource("comment")}}</label>
                    <textarea name="commennt" class="form-control" id="commentTextArea" rows="3"
                              [ngClass]="{'error-border': (formComment.Comment==null || formComment.Comment=='')  && submitted}"
                              [(ngModel)]="formComment.Comment"></textarea>
                    <span class="help-block has-error error-msg"
                          *ngIf="formComment.Comment==null && submitted">{{service.getResource("required")}}</span>
                </div>
                <button class="btn btn-dark" type="submit" (click)="setComment()" [disabled]="service.sentComment" >{{service.getResource("send_comment")}}</button>
            </form>
        </div>
    </div>
</div>
