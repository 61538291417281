<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">{{service.getResource("home")}}</a></li>
                    <li class="breadcrumb-item"><a href="/markalar">{{service.getResource("brands")}}</a></li>
                </ol>
            </nav>
        </div>
        <div class="col-lg-12 text-center">
            <h1 translate="brands"></h1>
            <p style="text-align: center" [innerHTML]="service.contentText"></p>
        </div>
        <div class="col-lg-12">
            <app-mdl-brand-details></app-mdl-brand-details>
            <div class="row">
                <div class="product-layout col-lg-3"
                     *ngFor="let cat of service.brands">
                    <div class="product-thumb transition options">
                        <div class="image"
                             style="border:1px solid #c0c0c0;min-height:364px; box-shadow:2px 2px 10px 10px #d6d6d6;margin-bottom:10px">
                            <div class="caption">
                                <div class="name">
                                    {{cat.BrandName}}
                                </div>
                            </div>
                            <a style="cursor:pointer"
                               href="/kataloglar/{{cat.UrlName}}">
                                <img alt="{{cat.BrandName}}" title="{{cat.BrandName}}" class="img-responsive"
                                     src="{{cat.SmallFileURL}}"
                                     id="img" *ngIf="cat.SmallFileURL!=null" style="width:270px;height:270px">
                                <img alt="{{cat.BrandName}}" title="{{cat.BrandName}}" class="img-responsive ng-hide"
                                     src="assets/images/bos.jpg"
                                     *ngIf="cat.SmallFileURL==null" style="width:270px;height:270px">
                            </a>
                            <a class="quickview" data-toggle="tooltip" title="" data-rel="details"
                               href="/kataloglar/{{cat.UrlName}}"
                               data-original-title="Quick View">
                                <div style="display: table-cell">
                                    <i class="fa fa-eye"></i></div>
                            </a>
                            <div class="col-sm-12 text-left" style="color:black; cursor: pointer;font-size: 11px;" *ngIf="cat.Description!=null">
                                <p>{{cat.Description | slice:0:40}}.... <a translate="read_more"
                                                                           (click)="onOpenBrandDetails(cat)"></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
