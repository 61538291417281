import {Component, Injectable, Inject, PLATFORM_ID, OnInit} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ActivatedRoute, Router, NavigationEnd, NavigationStart} from '@angular/router';
import * as $ from 'jquery';
import {config} from './config';
import {TranslationLoader} from '@angular-devkit/build-angular/src/utils/load-translations';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from "rxjs";
import {ServiceConnectionsService} from "./services/service-connections.service";
import {LocalstorageService} from "./services/storage-service.service";
import {HttpClient} from "@angular/common/http";
import {SharedService} from "./services/shared.service";

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {


    constructor(@Inject(PLATFORM_ID) private platformId: any,
                private translation: TranslateService, public localStorageService: LocalstorageService, private http: HttpClient, public service: SharedService) {
        this.isHtmlBrowser = isPlatformBrowser(this.platformId);
        AppComponent.isBrowser.next(isPlatformBrowser(platformId));
    }

    static isBrowser = new BehaviorSubject<boolean>(null);

    title = 'kinzi-ng-node';
    isHtmlBrowser = false;


    ngOnInit() {
        let languageName = this.localStorageService.getItem('language');
        if (languageName == null) {
            languageName = 'tr-TR';
            this.localStorageService.setItem('language', languageName);
        }
        this.translation.setDefaultLang(languageName);
        this.service.getResources();
    }


}
