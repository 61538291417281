<nav class="navbar navbar-expand-lg navbar-light bg-light bg-menu" style="box-shadow: 0 3px 3px 0 rgb(0 0 0 / 10%);">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
            (click)="onCloseCatalog()"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <h5 class="d-lg-none d-sm-none d-xl-none" style="float: right; margin-top: 10px;">İthal Duvar Kağıdı</h5>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav sf-menu">
            <li class="nav-item active">
                <a class="nav-link" routerLink="/" (click)="onCloseMenu()">{{service.getResource('home')}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/kataloglar" (click)="onCloseMenu()">{{service.getResource('catalogs')}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/modeller" (click)="onCloseMenu()">{{service.getResource('models')}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/markalar" (click)="onCloseMenu()">{{service.getResource('brands')}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/bloglar" (click)="onCloseMenu()">{{service.getResource('blog')}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/iletisim" (click)="onCloseMenu()">{{service.getResource('contact')}}</a>
            </li>
        </ul>
        <ul class="navbar-nav ml-auto sf-menu">
            <li class="nav-item active">
                <a class="nav-link" href="http://www.wallarti.ir/" target="_blank"><img
                        src="../../../assets/images/ir.png" height="11" width="16"/>
                    IR</a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="http://example.com" id="navbarDropdownMenuLink"
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{cultureName}}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style="width: 100px">
                    <a class="dropdown-item" (click)="onChangeLanguage('tr-TR', 'TR')">TR</a>
                    <a class="dropdown-item" (click)="onChangeLanguage('en-US', 'EN')">EN</a>
                </div>
            </li>
            <li class="nav-item active">
                <a class="nav-link" target="_blank" href="http://78.189.150.112:8888/">Bayi Girişi</a>
            </li>
        </ul>
    </div>
</nav>
