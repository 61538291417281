import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SharedService} from "../../services/shared.service";
import {ActivatedRoute} from "@angular/router";
import {isPlatformBrowser} from "@angular/common";

@Component({
    selector: 'app-blog-details',
    templateUrl: './blog-details.component.html',
    styleUrls: ['./blog-details.component.less']
})
export class BlogDetailsComponent implements OnInit {

    isBorwser: boolean;
    public testBrowser: boolean;
    public data: any;
    brandUrlName = null;

    constructor(private http: HttpClient, @Inject(PLATFORM_ID) platformId: string, public service: SharedService, private router: ActivatedRoute) {
        this.testBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        this.brandUrlName = this.router.snapshot.params.blogUrlName;
        this.service.getBlogDetails(this.brandUrlName);
    }

}
