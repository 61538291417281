export class Consts {
    // -- Api information
    public static protocol = 'https://';
    public static apiPath = 'api.ithalduvarkagidi.com/';
    public static resourcesFolder = 'resources/internal/';
    public static serviceType = 'svc';
    // -- Translate Service
    public static public = 'Public' + '.' + Consts.serviceType + '/';
    public static getProductDetails = 'GetProductDetails';
    public static getReferenceList = 'GetReferenceList';
    public static getHomePageData = 'GetHomePageData';
    public static getProductList = 'GetProductList';
    public static sendMessage = 'SendMessage';
    public static getContactInfo = 'GetContactInfo';
    public static getCatalogList = 'GetCatalogList';
    public static getBrandList = 'GetBrandList';
    public static addProductComment = 'AddProductComment';
    public static addProductRate = 'AddProductRate';
    public static getBlogList = 'GetBlogList';
    public static getBlogDetails = 'GetBlogDetails';
    public static getContentList = 'GetContentList';
    public static getPublicToken = 'GetPublicToken';
    public static getProductDetailsById = 'GetProductDetailsById';
    // -- Inside web
    public static nullValue = 'NullVallueOption';
    public static dataPerPage = 10;
    public static stockDataPerPage = 12;
    public static paginationPageSize = 1;
    public static pageDataPerPageProductionLine = 8;
}
