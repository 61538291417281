import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SharedService} from "../../services/shared.service";
import {ActivatedRoute} from "@angular/router";
import {isPlatformBrowser} from "@angular/common";
import {merge, fromEvent} from 'rxjs'
import {LazyLoadEvent} from "primeng/api";

@Component({
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.less']
})
export class ProductListComponent implements OnInit {

    isBorwser: boolean;
    public testBrowser: boolean;
    public data: any;
    catalogName = null;
    frmOrderIndex = 10;
    scroll$ = null;
    searchBrand = null;
    searchCatalog = null;
    requestParams = {
        Catalog: null,
        SelectedBrands: [],
        SelectedCatalogs: [],
        SelectedSelections: []
    }

    constructor(private http: HttpClient, @Inject(PLATFORM_ID) platformId: string, public service: SharedService, private router: ActivatedRoute) {
        this.testBrowser = isPlatformBrowser(platformId);
        this.scroll$ = merge(
            fromEvent(window, 'scroll')
        );
    }


    ngOnInit(): void {
        this.service.contentText = null;
        this.catalogName = this.router.snapshot.params.catalogName;
        if (this.catalogName != null)
            this.requestParams.Catalog = this.catalogName;
        this.onSearch();
    }

    onSearch() {
        this.requestParams.SelectedBrands = [];
        this.service.brands.forEach(b => {
            if (b.IsSelected) {
                this.requestParams.SelectedBrands.push(b.BrandId);
            }
        })
        this.requestParams.SelectedCatalogs = [];
        this.service.catalogs.forEach(b => {
            if (b.IsSelected) {
                this.requestParams.SelectedCatalogs.push(b.CatalogId);
            }
        })
        this.requestParams.SelectedSelections = [];
        this.service.properties?.forEach(b => {
            b.Selections?.forEach(s => {
                if (s.IsSelected) {
                    this.requestParams.SelectedSelections.push(s.SelectionId);
                }
            })
        })

        this.service.getProductList(this.requestParams);
    }

    loadCarsLazy(event: LazyLoadEvent) {
        // simulate remote connection with a timeout
        setTimeout(() => {
            //load data of required page
            let loadedProducts = this.service.productList.slice(event.first, (event.first + event.rows));

            //populate page of virtual cars
            Array.prototype.splice.apply(this.service.productList, [...[event.first, event.rows], ...loadedProducts]);

            //trigger change detection
            this.service.productList = [...this.service.productList];
        }, 1000);
    }

    loadIntegers(e) {
        setTimeout(() => this.service.productList = this.service.productList.slice(e.first, e.first + e.rows), 2000);
        console.log(`Calling loadIntegers from index ${e.first} from integer ${e.first + e.rows}. My total integers value is still ${this.service.productList.length}.`);
    }


    loadOtherIntegers(e) {
        setTimeout(() => this.service.productList = this.service.productList.slice(e.first, e.first + e.rows), 2000);
        console.log(`Calling loadOtherIntegers from index ${e.first} from integer ${e.first + e.rows}. My total integers value is still ${this.service.productList.length}. I will never retrieve my last otherInteger...`);
    }

    onChangeProp() {
        let type = 1;
        if (this.frmOrderIndex == 10) {
            type = 1;
        } else {
            type = -1;
        }

        this.sortBy('ProductName', type);
    }

    sortBy(prop: string, type) {
        return this.service.productList.sort((a, b) => a[prop] > b[prop] ? (type) * 1 : a[prop] === b[prop] ? 0 : (type) * (-1));
    }
}
