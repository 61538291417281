import {Injectable} from '@angular/core';
import {Api} from '../http/Api';
import {Consts} from '../models/consts/consts';
import {Enums} from '../models/enums/enums';
import {ServiceConnectionsService} from './service-connections.service';
import {ErrorManagementService} from './error/error-management.service';
import {Meta, Title} from '@angular/platform-browser';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    constructor(private api: Api, private serviceConnectionService: ServiceConnectionsService,
                private metaTagService: Meta,
                private http: HttpClient,
                private pageTitle: Title) {
    }

    serviceBaseUrl = Consts.protocol + Consts.apiPath + Consts.public;
    sliderImages = [];
    topRatedProducts = [];
    lastAddedProducts = [];
    blogs = [];
    catalogs = [];
    productGroups = [];
    brands = [];
    notices = {
        NoticeText: null
    };
    choosedBrand = {
        BrandName: null,
        Files: [],
        Description: null
    };
    contentText = null;
    blogList = [];
    blogDetails = null;
    productList = [];
    catalogName = null;
    productDetails = {
        BigFiles: [],
        Comments: [],
        ProductId: null,
        ProductCode: null,
        ProductName: null,
        ProductGroupName: null,
        BrandName: null,
        OriginName: null,
        BaseName: null,
        Width: null,
        Weight: null,
        Height: null,
        RateCount: null,
        AverageRate: null,
        Description: null,
        CatalogName: null
    };
    productImages = [];
    relatedProduct = [];
    addedCommentSuccessFully = false;
    sendContact = null;
    resources = null;
    mainPage = null;
    sentComment = false;

    properties = [];

    catalogUrlName = null;

    // -- GetHomePageData
    getHomePageData() {
        const methodUrl = this.serviceBaseUrl + Consts.getHomePageData;
        this.serviceConnectionService.serviceConnection(methodUrl, null, Enums.MethodType.GET)
            .subscribe(resp => {
                const data = this.serviceConnectionService.parseDataToJsonDetails(resp);
                this.getFooterDatas(data);
                this.getMetaTags(data.Contents[0]);
                this.sliderImages = data.SliderImages;
                if (data.Notices != null && data.Notices.length > 0) {
                    this.notices = data.Notices[0];
                    document.getElementById('btnMdlNotice').click();
                }
            }, error => {
                ErrorManagementService.onShowErrorMessage(error);
            });
    }

    // -- GetCatalogList
    getCatalogList(params) {
        const methodUrl = this.serviceBaseUrl + Consts.getCatalogList;
        this.catalogs = [];
        this.serviceConnectionService.serviceConnection(methodUrl, params, Enums.MethodType.POST)
            .subscribe(resp => {
                const data = this.serviceConnectionService.parseDataToJsonDetails(resp);
                if (this.productGroups?.length == 0) {
                    this.productGroups = data.ProductGroups;
                }
                if (this.brands?.length == 0) {
                    this.brands = data.Brands;
                }
                this.catalogs = data.Records;
                if (this.catalogs != null) {
                    this.catalogs?.forEach(c => {
                        if (c.Files != null) {
                            c.SmallFileURL = c.Files[0].FileURL;
                        }
                    });
                }
                this.getFooterDatas(data);
                if (data?.Contents != null) {
                    this.getMetaTags(data.Contents[0]);
                    this.contentText = data.Contents[0].Text;
                }
            }, error => {
                ErrorManagementService.onShowErrorMessage(error);
            });
    }

    // -- getBrandList
    getBrandList() {
        const methodUrl = this.serviceBaseUrl + Consts.getBrandList;
        this.serviceConnectionService.serviceConnection(methodUrl, null, Enums.MethodType.GET)
            .subscribe(resp => {
                const data = this.serviceConnectionService.parseDataToJsonDetails(resp);
                this.brands = data.Records;
                if (this.brands != null) {
                    this.brands?.forEach(c => {
                        c.Description = c.Description?.replace(/<[^>]*>/g, '');
                        if (c.Files != null) {
                            c.SmallFileURL = c.Files[0].FileURL;
                        }
                    });
                }
                this.getFooterDatas(data);
                if (data?.Contents != null) {
                    this.getMetaTags(data.Contents[0]);
                    this.contentText = data.Contents[0].Text;
                }
            }, error => {
                ErrorManagementService.onShowErrorMessage(error);
            });
    }

    // -- getBlogList
    getBlogList() {
        const methodUrl = this.serviceBaseUrl + Consts.getBlogList;
        this.serviceConnectionService.serviceConnection(methodUrl, null, Enums.MethodType.GET)
            .subscribe(resp => {
                const data = this.serviceConnectionService.parseDataToJsonDetails(resp);
                this.blogList = data.Records;
                if (this.blogList != null) {
                    this.blogList?.forEach(c => {
                        c.Text = c.Text?.replace(/<[^>]*>/g, '');
                        if (c.Files != null) {
                            c.SmallFileURL = c.Files[0].FileURL;
                        }
                    });
                }
                this.getFooterDatas(data);
                if (data?.Contents != null) {
                    this.getMetaTags(data.Contents[0]);
                    this.contentText = data.Contents[0].Text;
                }
            }, error => {
                ErrorManagementService.onShowErrorMessage(error);
            });
    }

    // -- getBlogDetails
    getBlogDetails(params) {
        const methodUrl = this.serviceBaseUrl + Consts.getBlogDetails + '/' + params;
        this.serviceConnectionService.serviceConnection(methodUrl, null, Enums.MethodType.GET)
            .subscribe(resp => {
                const data = this.serviceConnectionService.parseDataToJsonDetails(resp);
                this.blogDetails = data.Records[0];
                this.getFooterDatas(data);
                const p = {
                    Title: this.blogDetails.Title + ' - ' + 'İthal Duvar Kağıdı',
                    Text: this.blogDetails.Text,
                    Keywords: this.blogDetails.Keywords
                };
                this.getMetaTags(p);
            }, error => {
                ErrorManagementService.onShowErrorMessage(error);
            });
    }

    // -- getProductList
    getProductList(params) {
        let methodUrl = this.serviceBaseUrl + Consts.getProductList;
        this.serviceConnectionService.serviceConnection(methodUrl, params, Enums.MethodType.POST)
            .subscribe(resp => {
                const data = this.serviceConnectionService.parseDataToJsonDetails(resp);
                const records = data.Records;
                // this.catalogName = records.CatalogName;
                this.productList = [];

                data.Records?.forEach(r => {
                    r.Products.forEach(p => {
                        p.CatalogName = r.CatalogName;
                        p.CatalogURLName = r.CatalogURLName;
                        this.productList.push(p);
                    });
                });

                if (this.brands.length == 0) {
                    this.brands = data.Brands;
                }
                if (this.catalogs.length == 0) {
                    this.catalogs = data.Catalogs;
                }

                if (this.properties.length == 0) {
                    this.properties = data.Properties;
                }
                this.getFooterDatas(data);
                const p = {
                    Title: null,
                    Text: null,
                    Keywords: null
                };
                if (this.catalogName != null) {
                    p.Title = this.catalogName + ' Modelleri - ' + 'İthal Duvar Kağıdı';
                } else {
                    p.Title = ' Modeller - ' + 'İthal Duvar Kağıdı';
                }
                if (data.Contents != null) {
                    p.Keywords = data.Contents[0].Keywords;
                    p.Text = data.Contents[0].Text;
                } else {
                    p.Keywords = '';
                    data.Records?.forEach(product => {
                        p.Keywords += product.CatalogName + ',';
                    });
                }
                this.getMetaTags(p);

            }, error => {
                ErrorManagementService.onShowErrorMessage(error);
            });
    }

    // -- getProducDetails
    getProductDetails(params) {
        const methodUrl = this.serviceBaseUrl + Consts.getProductDetails + '/' + params.catalogUrlName + '/' + params.productUrlName;
        this.serviceConnectionService.serviceConnection(methodUrl, null, Enums.MethodType.GET)
            .subscribe(resp => {
                const data = this.serviceConnectionService.parseDataToJsonDetails(resp);
                this.productDetails = data.Records[0];
                this.productDetails.Comments = data.Comments;
                this.properties = data.Properties;
                if (this.productDetails.AverageRate == null) {
                    this.productDetails.AverageRate = 0;
                }
                this.catalogName = data.CatalogName;
                this.catalogUrlName = data.CatalogUrlName;
                this.productDetails.BigFiles?.forEach(bg => {
                    const p = {
                        image: bg.FileURL,
                        thumbImage: bg.FileURL,
                        alt: this.catalogName + ' - ' + this.productDetails.ProductName + ' - İthal Duvar Kağıdı',
                        title: this.catalogName + ' - ' + this.productDetails.ProductName + ' - İthal Duvar Kağıdı'
                    };
                    this.sliderImages.push(p);
                });
                data.ReleatedProducts?.forEach(rp => {
                    if (rp.SmallFiles != null) {
                        const p = {
                            image: rp.SmallFiles[0].FileURL,
                            thumbImage: rp.SmallFiles[0].FileURL,
                            CatalogUrlName: rp.CatalogUrlName,
                            ProductUrlName: rp.ProductUrlName,
                            alt: rp.CatalogUrlName + ' - ' + rp.ProductName + ' - İthal Duvar Kağıdı',
                            title: rp.CatalogUrlName + ' - ' + rp.ProductName + ' - İthal Duvar Kağıdı'
                        };
                        this.relatedProduct.push(p);
                    }
                });
                this.getFooterDatas(data);
                const p = {
                    Title: this.catalogName + ' - ' + this.productDetails.ProductName + ' - İthal Duvar Kağıdı',
                    Text: this.productDetails.BrandName + ' markası ' + this.productDetails.ProductName + ' duvar kağıdımız tüm estetikliği ile evinize renk katmaktadır. ' + this.productDetails.ProductName + ' duvar kağıdımız hakkında tüm detaylara ulaşmak için hemen tıklayın!',
                    Keywords: 'İthal Duvar Kağıdı ' + this.productDetails.ProductName + ',' + this.productDetails.ProductCode + ',' + this.productDetails.BaseName + ', ' + this.productDetails.BrandName
                };
                this.getMetaTags(p);
                // this.contentText = data.Contents[0].Text;

            }, error => {
                ErrorManagementService.onShowErrorMessage(error);
            });
    }

    // -- AddProductRate
    addProductRate(params) {
        const methodUrl = this.serviceBaseUrl + Consts.addProductRate;
        this.serviceConnectionService.serviceConnection(methodUrl, params, Enums.MethodType.POST)
            .subscribe(resp => {
                const data = this.serviceConnectionService.parseDataToJsonDetails(resp);

            }, error => {
                ErrorManagementService.onShowErrorMessage(error);
            });
    }

    // -- AddProductComment
    addProductComment(params) {
        const methodUrl = this.serviceBaseUrl + Consts.addProductComment;
        this.sentComment = true;
        this.serviceConnectionService.serviceConnection(methodUrl, params, Enums.MethodType.POST)
            .subscribe(resp => {
                this.sentComment = false;
                const data = this.serviceConnectionService.parseDataToJsonDetails(resp);
                this.addedCommentSuccessFully = true;
            }, error => {
                this.sentComment = false;
                ErrorManagementService.onShowErrorMessage(error);
            });
    }

    // -- SendMessage
    sendMessage(params) {
        const methodUrl = this.serviceBaseUrl + Consts.sendMessage;
        this.serviceConnectionService.serviceConnection(methodUrl, params, Enums.MethodType.POST)
            .subscribe(resp => {
                const data = this.serviceConnectionService.parseDataToJsonDetails(resp);
                this.sendContact = true;
            }, error => {
                ErrorManagementService.onShowErrorMessage(error);
                this.sendContact = false;
            });
    }

    // -- GgetContactInfo
    getContactInfo() {
        const methodUrl = this.serviceBaseUrl + Consts.getContactInfo;
        this.serviceConnectionService.serviceConnection(methodUrl, null, Enums.MethodType.GET)
            .subscribe(resp => {
                const data = this.serviceConnectionService.parseDataToJsonDetails(resp);
                this.getFooterDatas(data);
                if (data?.Contents != null) {
                    this.getMetaTags(data.Contents[0]);
                    this.contentText = data.Contents[0].Text;
                }
            }, error => {
                ErrorManagementService.onShowErrorMessage(error);
            });
    }

    // -- getResources
    getResources() {
        let lang = this.serviceConnectionService.getHandleStorageData('language');
        if (lang == null) {
            lang = 'tr-TR';
        }
        const methodUrl = Consts.protocol + Consts.apiPath + '/resources/' + lang + '.json';
        this.http.get(methodUrl, {responseType: 'text', observe: 'response'})
            .subscribe(resp => {
                this.resources = resp.body;
            });
    }

    getData() {
        return this.api.get('get-date');
    }

    getMetaTags(content) {
        this.pageTitle.setTitle(content.Title);
        if (content.Keywords != null) {
            this.metaTagService.addTags([
                {name: 'keywords', content: content.Keywords}
            ]);
        }
        if (content.Text != null) {
            this.metaTagService.addTags([,
                {name: 'description', content: content.Text?.replace(/<[^>]*>/g, '')}
            ]);
        }
    }

    getFooterDatas(data) {
        this.topRatedProducts = data.TopRatedProducts;
        this.lastAddedProducts = data.LastAddedProducts;
        this.blogs = data.Blogs;
        this.blogs?.forEach(b => {
            b.TextPreview = b.TextPreview.replace(/<[^>]*>/g, '');
        });
    }

    getResource(resourceName) {
        if (this.resources != null) {
            const s = this.resources.toString();
            const d = s.substr(1, s.lastIndexOf('}') - 1);
            const dArray = d.split(',');
            let resource = [];
            let result = resourceName;
            dArray.forEach(x => {
                resource = x.toString().split(':');
                let mainResource = resource[0].toString().trim();
                mainResource = mainResource.substr(1, mainResource.toString().length - 2);
                if (mainResource == resourceName) {
                    let returnResource = resource[1].toString().trim();
                    returnResource = returnResource.substr(1, returnResource.toString().length - 2);
                    result = returnResource;
                }
            });
            return result;
        }
        return resourceName;
    }
}
