<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-body">
        <div class="close-button-container" (click)="close()">
            <div class="display-table-cell">
                <i class="fas fa-times"></i>
            </div>
        </div>
        <div class="main-template container-fluid" [innerHTML]="service.notices.NoticeText">

        </div>
    </div>
</ng-template>
<button class="btn btn-lg btn-outline-primary" id="btnMdlNotice"
        (click)="open(content)" style="display: none">Launch demo modal
</button>
