<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">{{service.getResource("home")}}</a></li>
                    <li class="breadcrumb-item"><a href="/bloglar">{{service.getResource("blog")}}</a></li>
                    <li class="breadcrumb-item"><a *ngIf="service.blogDetails!=null">{{service.blogDetails.Title}}</a></li>
                </ol>
            </nav>
        </div>
    </div>
    <div style="height: calc(100vh - 91px)" *ngIf="service.blogDetails==null"></div>

    <div class="box-content" *ngIf="service.blogDetails!=null">
        <div class="row">
            <div class="col-sm-12">
                <div class="text-center">
                    <h3>{{service.blogDetails.Title}}</h3>
                    <p class="text-right offset-sm-1 col-sm-10 ng-binding">{{service.blogDetails.CreatedDate | date:'dd/MM/yyyy'}}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="offset-sm-1 col-sm-10" [innerHTML]="service.blogDetails.Text">

            </div>
        </div>
    </div>
</div>
