<div class="container">
    <div style="height: calc(100vh - 91px)" *ngIf="service.blogList?.length==0"></div>
    <div class="row">
        <div class="col-sm-12">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">{{service.getResource("home")}}</a></li>
                    <li class="breadcrumb-item"><a href="/bloglar">{{service.getResource("blog")}}</a></li>
                </ol>
            </nav>
        </div>
        <div class="col-sm-6 blog-message" *ngFor="let blog of service.blogList">
            <div class="row">
                <div class="col-sm-3 blog-date">
                    <a href="/blog-detayi/{{blog.URLName}}" style="color: #aeaeae; text-decoration: unset">
                        <p><span>{{blog.CreatedDate | date:'dd'}}</span></p>
                        <p><span class="ng-binding">{{blog.CreatedDate | date:'MM/yyyy'}}</span></p></a>
                </div>
                <div class="col-sm-9 blog-content3">
                    <a href="/blog-detayi/{{blog.URLName}}" style="color: #aeaeae; text-decoration: unset">
                        <h4>{{blog.Title}}</h4>
                        <p [innerHTML]="blog.Text | slice:0:200"></p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
