import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Enums} from '../models/enums/enums';
import {Consts} from '../models/consts/consts';
import {LocalstorageService} from "./storage-service.service";

@Injectable({
    providedIn: 'root'
})
export class ServiceConnectionsService {
    constructor(private http: HttpClient, public localStorageService: LocalstorageService) {
    }

    sendingParams = {};
    pageNo = null;
    dataPerPage = 10;
    page = null;

    serviceConnection(methodUrl, params, methodType, keepRequest = null) {
        if (params != null) {
            this.sendingParams = JSON.stringify(params);
        }
        if (keepRequest == true) {
            const p = {
                RequestParams: params,
                PageNo: this.pageNo,
                PageNumber: this.page,
                DataPerPage: this.dataPerPage
            };
            this.setHandleStorageData('requestParams', JSON.stringify(p));
        }
        let headers = new HttpHeaders();
        if (this.getHandleStorageData('token') != null) {
            headers = headers.set('Token', this.getHandleStorageData('token'));
        }
        if (this.getHandleStorageData('language') != null) {
            headers = headers.set('Accept-Language', this.getHandleStorageData('language'));
        }
        if (methodType === Enums.MethodType.POST) {
            const data = this.http.post(methodUrl, this.sendingParams, {headers, observe: 'response'});
            return data;
        } else if (methodType === Enums.MethodType.GET) {
            const data = this.http.get(methodUrl, {headers, observe: 'response'});
            return data;
        } else if (methodType === Enums.MethodType.DELETE) {
            const data = this.http.delete(methodUrl, {headers, observe: 'response'});
            return data;
        }

    }

    parseData(data) {
        if (data.body != null) {
            data = data.body;
        }
        const d = JSON.parse(data.Data);
        return d;
    }

    // -- Records parse
    parseDataToJsonList(data) {
        if (data.body != null) {
            data = data.body;
        }
        if (data.Data != null) {
            const d = JSON.parse(data.Data);
            return d;
        }
    }

    parseDataToJsonDetails(data) {
        if (data.body != null) {
            data = data.body;
        }
        const d = JSON.parse(data.Data);
        return d;
    }

    parseRecordsToList(data) {
        if (data.body != null) {
            data = data.body;
        }
        return data.Records;
    }

    parseRecordsToDetails(data) {
        if (data.body != null) {
            data = data.body;
        }
        if (data.Records != null) {
            return data.Records[0];
        }
    }

    // -- Local storage methods
    setHandleStorageData(key, value) {
        this.localStorageService.setItem(key, value);
    }

    getHandleStorageData(key) {
        return this.localStorageService.getItem(key);
    }

    fillRequestParams() {
        let requestParams = null;
        this.page = 1;
        this.dataPerPage = 10;
        const keptParameters = this.fnCheckExistRequestParams(this.pageNo);
        if (keptParameters?.PageNo == this.pageNo) {
            if (keptParameters?.RequestParams != null) {
                requestParams = keptParameters?.RequestParams;
            }
            if (keptParameters?.PageNumber != null) {
                this.page = keptParameters?.PageNumber;
            }
            if (keptParameters?.DataPerPage != null) {
                this.dataPerPage = keptParameters?.DataPerPage;
            }
        }
        return requestParams;
    }


    fnCheckExistRequestParams(pageNo) {
        let params = null;

        if (this.getHandleStorageData('requestParams') != null) {
            const checkRequestParams = JSON.parse(this.getHandleStorageData('requestParams'));
            // tslint:disable-next-line:triple-equals
            if (checkRequestParams != null && checkRequestParams.PageNo == pageNo && checkRequestParams.RequestParams != 'null') {
                params = checkRequestParams;
            } else {
                this.setHandleStorageData('requestParams', null);
            }
        }
        return params;
    }

    fnUpdateKeeptRequest(request) {
        const p = {
            RequestParams: request,
            PageNo: this.pageNo,
            PageNumber: this.page,
            DataPerPage: this.dataPerPage
        };
        this.setHandleStorageData('requestParams', JSON.stringify(p));
        // this.fnPreparePageNo();
    }

    fnPreparePageNo() {
        const keptPage = this.fnCheckExistRequestParams(this.pageNo);
        if (keptPage?.PageNumber != null) {
            this.page = keptPage?.PageNumber;
        }
    }


}
