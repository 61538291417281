<div class="container">

    <div style="height: calc(100vh - 91px)" *ngIf="service.catalogs?.length==0"></div>

    <div class="row">
        <div class="col-sm-12">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">{{service.getResource("home")}}</a></li>
                    <li class="breadcrumb-item"><a href="/kataloglar">{{service.getResource("catalogs")}}</a></li>
                </ol>
            </nav>
        </div>
        <div class="col-lg-3"></div>
        <div class="col-lg-9 text-center">
            <h1>{{service.getResource("title_models")}}</h1>
            <p style="text-align: center" [innerHTML]="service.contentText"></p>
        </div>
        <div class="col-lg-3">
            <div id="product">
                <div class="product-options">
                    <h3>{{service.getResource("search_catalog")}}</h3></div>
            </div>
            <form>
                <div class="form-group">
                    <label>{{service.getResource("product_group_label")}}</label>
                    <select class="form-control" name="productGroup" (change)="onCmbChanged()"
                            [(ngModel)]="frmSearchProduct.ProductGroupId">
                        <option selected [ngValue]="null">{{service.getResource("all")}}</option>
                        <option *ngFor="let pg of service.productGroups"
                                value="{{pg.ProductGroupId}}">{{pg.ProductGroupName}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>{{service.getResource("brand_label")}}</label>
                    <select class="form-control" name="brand" (change)="onCmbChanged()"
                            [(ngModel)]="frmSearchProduct.BrandId">
                        <option selected [ngValue]="null">{{service.getResource("all")}}</option>
                        <option *ngFor="let b of service.brands; let last = last" [ngValue]="b.BrandId"
                                [attr.ready]="b.IsSelected==1 && setValue(b)"
                                [selected]="b.IsSelected==1">{{b.BrandName}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>{{service.getResource("search_catalog")}}</label>
                    <input class="form-control" name="search" (keydown.enter)="onSearch()"
                           [(ngModel)]="frmSearchProduct.SearchText">
                </div>
                <div class="form-group">
                    <button type="submit" id="tm-newsletter-popup-button" class="btn newsletter-popup-btn"
                            (click)="onSearch()">
                        <span  name="btnSearch">{{service.getResource("search")}}</span>
                    </button>
                </div>
            </form>
        </div>
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <select class="form-control" name="productGroup" [(ngModel)]="frmOrderIndex"
                                (change)="onChangeProp()">
                            <option selected [ngValue]="10">{{service.getResource("order_index_first_last")}}</option>
                            <option selected [ngValue]="20">{{service.getResource("order_index_last_first")}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="product-layout col-lg-4"
                     *ngFor="let cat of service.catalogs">
                    <div class="product-thumb transition options">
                        <div class="image">
                            <a style="cursor:pointer"
                               href="modeller/{{cat.UrlName}}">
                                <div class="brown-background"></div>
                                <img alt="{{cat.CatalogName}}" title="{{cat.CatalogName}}" class="img-responsive"
                                     src="{{cat.SmallFileURL}}"
                                     id="img" *ngIf="cat.SmallFileURL!=null" style="width:270px;height:270px">
                                <img alt="{{cat.CatalogName}}" title="{{cat.CatalogName}}" class="img-responsive ng-hide"
                                     src="assets/images/bos.jpg"
                                     *ngIf="cat.SmallFileURL==null" style="width:270px;height:270px">
                            </a>
                            <a class="quickview" data-toggle="tooltip" title="" data-rel="details"
                               href="modeller/{{cat.UrlName}}"
                               data-original-title="Quick View">
                                <div style="display: table-cell">
                                    <i class="fa fa-eye"></i></div>
                            </a>
                        </div>
                        <div class="caption">
                            <div class="name ng-binding">
                                {{cat.CatalogName}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
