<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-body">
        <div class="close-button-container" (click)="close()">
            <div class="display-table-cell">
                <i class="fas fa-times"></i>
            </div>
        </div>
        <div class="main-template container-fluid">

            <div class="row">
                <div class="col-sm-4">
                    <img alt="{{service.choosedBrand.BrandName}}" title="{{service.choosedBrand.BrandName}}"
                         class="img-responsive" src="{{service.choosedBrand.Files[0].FileURL}}"
                         *ngIf="service.choosedBrand.Files!=null" style="width:270px;height:270px">
                    <img alt="{{service.choosedBrand.BrandName}}" title="{{service.choosedBrand.BrandName}}"
                         class="img-responsive" src="assets/images/bos.jpg"
                         *ngIf="service.choosedBrand.Files==null"
                         style="width:270px;height:270px">

                </div>
                <div class="col-sm-8 text-left" style="color:black;">
                    {{service.choosedBrand.Description}}
                </div>
            </div>
        </div>
    </div>
</ng-template>
<button class="btn btn-lg btn-outline-primary" id="btnOpenBrandDetail"
        (click)="open(content)" style="display: none">Launch demo modal
</button>
