import {Component, OnInit, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Meta, Title} from '@angular/platform-browser';
import {config} from '../../config';
import {ActivatedRoute, Router, NavigationEnd, NavigationStart} from '@angular/router';
import {SharedService} from '../../services/shared.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {response} from "express";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.less']
})

export class HomeComponent implements OnInit {

    isBorwser: boolean;
    public testBrowser: boolean;
    public data: any;

    constructor(private http: HttpClient, @Inject(PLATFORM_ID) platformId: string, public service: SharedService) {
        this.testBrowser = isPlatformBrowser(platformId);
    }


    ngOnInit() {
        this.service.contentText = null;
        this.service.getHomePageData();
    }

    getMetaTags() {
    }
}
