import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {CatalogsComponent} from './pages/catalogs/catalogs.component';
import {BrandsComponent} from './pages/brands/brands.component';
import {BlogsComponent} from "./pages/blogs/blogs.component";
import {BlogDetailsComponent} from "./pages/blog-details/blog-details.component";
import {ProductListComponent} from "./pages/product-list/product-list.component";
import {ContactsComponent} from "./pages/contacts/contacts.component";
import {ProductDetailsComponent} from "./pages/product-details/product-details.component";

const routes: Routes = [
    {path: '', pathMatch: 'full', redirectTo: 'home'},
    {path: 'home', component: HomeComponent},
    {path: 'kataloglar', component: CatalogsComponent},
    {path: 'kataloglar/:brandUrlName', component: CatalogsComponent},
    {path: 'markalar', component: BrandsComponent},
    {path: 'bloglar', component: BlogsComponent},
    {path: 'blog-detayi/:blogUrlName', component: BlogDetailsComponent},
    {path: 'modeller', component: ProductListComponent},
    {path: 'modeller/:catalogName', component: ProductListComponent},
    {path: 'model-detayi/:catalogUrlName/:productUrlName', component: ProductDetailsComponent},
    {path: 'iletisim', component: ContactsComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {initialNavigation: 'enabled', relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

