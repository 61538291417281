<div class="container contact_page">
    <div class="row">
        <div class="col-sm-12">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">{{service.getResource("home")}}</a></li>
                    <li class="breadcrumb-item"><a>{{service.getResource("contact")}}</a></li>
                </ol>
            </nav>
        </div>
        <div class="col-sm-12 text-center">
            <h1 class="no-line" translate="contact_us"></h1>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12043.837568045756!2d29.1542785!3d41.004262!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb1b045cbc59913a7!2zV2FsbGFydMSxIMSwdGhhbCBEdXZhciBLYcSfxLFkxLE!5e0!3m2!1str!2sua!4v1512208300666"
                    width="100%" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>

        </div>
        <div class="col-sm-12">
            <h3 translate="contact_information" style="margin-top:35px"></h3>
            <div class="panel panel-default">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-sm-4">
                            <a href="./">
                                <img src="assets/images/logo.png" alt="Wall Artı" title="Wall Artı"
                                     class="img-thumbnail">
                            </a>
                        </div>
                        <div class="col-sm-4">
                            <div class="fa fa-phone">
                                <strong translate="phone" class="ng-scope">Telefon</strong><br>
                                <a href="callto:0(216)5402430" style="color: #aeaeae">0 (216) 540 24 30 - 31</a>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="fa fa-key">
                                <strong translate="working_times"></strong><br>
                                <span translate="time_information"></span> 9:30 - 18:30
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <strong>Wall Artı</strong><br>
                            <address>
                                Yeni Bostancı Yolu Cad. Keyap Sitesi J2 Blok No: 162 Dudulu - İstanbul
                            </address>
                        </div>
                        <div class="col-sm-4">
                            <div class="fa fa-fax">
                                <strong translate="fax"></strong><br>
                                <a href="callto:0(216)5402432" style="color: #aeaeae">0 (216) 540 24 32</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <form enctype="multipart/form-data"
                  class="form-horizontal ng-pristine ng-invalid ng-invalid-required ng-valid-email"
                  ng-submit="onSubmit()" novalidate="novalidate">
                <fieldset>
                    <h3 style="margin-top:35px;border-bottom: 2px solid #a37e47;">{{service.getResource("contact_form")}}</h3>
                    <div style="border:1px solid #ff4d4d; background-color: #ff9999; height: 60px;display: table;width: 100%;font-weight: bold;color: white"
                         *ngIf="service.sendContact==true">
                        <div style="display: table-cell;vertical-align: middle;text-align: center"
                             translate="something_went_wrong"></div>
                    </div>
                    <div style="border:1px solid  rgb(5, 115, 19); background-color:  rgb(5, 115, 19)
; height: 60px;display: table;width: 100%;font-weight: bold;color: white" *ngIf="service.sendContact==true">
                        <div style="display: table-cell;vertical-align: middle;text-align: center"
                             translate="successfully">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-2 control-label ng-scope" for="input-name" translate="name"></label>
                        <div class="col-sm-10">
                            <input type="text" name="name" id="input-name" class="form-control"
                                   [ngClass]="{'error-border': (frmContactForm.Title==null  || frmContactForm.Title=='') && submitted}"
                                   [(ngModel)]="frmContactForm.Title">
                            <span class="help-block has-error error-msg" translate="required"
                                  *ngIf="(frmContactForm.Title==null  || frmContactForm.Title=='')  && submitted"></span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-2 control-label" for="input-phone" translate="phone_number"></label>
                        <div class="col-sm-10">
                            <input type="phoneNumber" name="phoneNumber" value="" id="input-phone" class="form-control"
                                   [ngClass]="{'error-border': (frmContactForm.PhoneNumber==null || frmContactForm.PhoneNumber=='') && submitted}"
                                   [(ngModel)]="frmContactForm.PhoneNumber">
                            <span class="help-block has-error error-msg" translate="required"
                                  *ngIf="(frmContactForm.PhoneNumber==null || frmContactForm.PhoneNumber=='') && submitted"></span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-2 control-label" for="input-email" translate="email"></label>
                        <div class="col-sm-10">
                            <input type="email" name="email" value="" id="input-email" class="form-control"
                                   [(ngModel)]="frmContactForm.Email"
                                   [ngClass]="{'error-border': (frmContactForm.Email==null || frmContactForm.Email=='') && submitted}">
                            <span class="help-block has-error error-msg" translate="required"
                                  *ngIf="(frmContactForm.Email==null || frmContactForm.Email=='') && submitted"></span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-2 control-label" for="input-enquiry" translate="message"></label>
                        <div class="col-sm-10">
                            <textarea name="enquiry" class="form-control" rows="10" id="input-enquiry"
                                      [ngClass]="{'error-border': (frmContactForm.MessageText==null || frmContactForm.MessageText=='') && submitted}"
                                      [(ngModel)]="frmContactForm.MessageText"></textarea>
                            <span class="help-block has-error error-msg" translate="required"
                                  *ngIf="(frmContactForm.MessageText==null || frmContactForm.MessageText=='')  && submitted"></span>
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-dark" type="submit" translate="send" (click)="submitForm()"></button>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>
