<!-- Footer -->
<footer class="bg-light text-center text-lg-start footer-menu">
    <!-- Grid container -->
    <div class="container p-4">
        <!--Grid row-->
        <div class="row">
            <!--Grid column-->
            <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
                <h5 style="color: #9a9797;">{{service.getResource("last_added_models")}}</h5>
                <ul class="list-unstyled mb-0">
                    <li *ngFor="let p of service.topRatedProducts" style="margin-bottom: 5px">
                        <img class="footer-product-img" src="{{p.SmallFiles[0].FileURL}}" width="35"
                             alt="{{p.ProductUrlName}}">
                        <a href="/model-detayi/{{p.CatalogUrlName}}/{{p.ProductUrlName}}"
                           class="color-link">{{p.ProductName}}</a>
                    </li>
                </ul>
            </div>
            <!--Grid column-->
            <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
                <h5 style="color: #9a9797;">{{service.getResource("popular_models")}}</h5>
                <ul class="list-unstyled mb-0">
                    <li *ngFor="let p of service.lastAddedProducts" style="margin-bottom: 5px">
                        <img class="footer-product-img" src="{{p.SmallFiles[0].FileURL}}" width="35"
                             alt="{{p.ProductUrlName}}">
                        <a href="/model-detayi/{{p.CatalogUrlName}}/{{p.ProductUrlName}}"
                           class="color-link">{{p.ProductName}}</a>
                    </li>
                </ul>
            </div>


            <!--Grid column-->
            <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
                <h5 class="mb-0" style="color: #9a9797;">{{service.getResource("blog_texts")}}</h5>
                <div class="col-sm-12" *ngFor="let blog of service.blogs" style="margin-bottom: 30px">
                    <a href="/blog-detayi/{{blog.BlogURLName}}">
                        <div class="row">
                            <div class="col-9 text-left">
                                <b style="color: #ccc">{{blog.Title}}</b>
                            </div>
                            <div class="text-right col-3"><b
                                    class="date-text">{{blog.CreatedDate | date:'dd/MM/yyyy'}}</b></div>
                            <div class="col-12 text-left" style="color: #aeaeae!important;">
                                <span [innerHTML]="blog.TextPreview"></span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <!--Grid column-->
        </div>
        <!--Grid row-->
    </div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light footer-menu">
        <ul class="navbar-nav">
            <li class="nav-item active">
                <a class="nav-link">
                    © 2021 Copyright: ithalduvarkagidi.com
                </a>
            </li>
        </ul>
        <ul class="navbar-nav ml-auto">
            <ul class="navbar-nav">
                <li class="nav-item active">
                    <a class="nav-link" routerLink="/">{{service.getResource("home")}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/modeller">{{service.getResource("brands")}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/markalar">{{service.getResource("brands")}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/bloglar">{{service.getResource("blog")}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/iletisim">{{service.getResource("contact")}}</a>
                </li>
            </ul>
        </ul>
    </nav>

    <!-- Copyright -->
</footer>
