import {Component, OnInit} from '@angular/core';
import {SharedService} from "../../services/shared.service";

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.less']
})
export class ContactsComponent implements OnInit {

    constructor(public service: SharedService) {
    }

    frmContactForm = {
        Title: null,
        Email: null,
        PhoneNumber: null,
        MessageText: null
    };
    submitted = false;

    ngOnInit(): void {
        this.service.getContactInfo();
    }

    submitForm() {
        this.submitted = true;
        if (this.frmContactForm.Title != null && this.frmContactForm.Title != "" &&
            this.frmContactForm.PhoneNumber != null && this.frmContactForm.PhoneNumber != "" &&
            this.frmContactForm.Email != null && this.frmContactForm.Email != "" &&
            this.frmContactForm.MessageText != null && this.frmContactForm.MessageText != "") {
            this.service.sendMessage(this.frmContactForm);
        }
    }
}
