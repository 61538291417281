export class Enums {


  public static MethodType =
    {
      POST: 'POST',
      GET: 'GET',
      DELETE: 'DELETE',
      PUT: 'PUT'
    };

}
