import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SharedService} from '../../services/shared.service';
import {isPlatformBrowser} from '@angular/common';
import {MdlBrandDetailsComponent} from '../mdl-brand-details/mdl-brand-details.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-brands',
    templateUrl: './brands.component.html',
    styleUrls: ['./brands.component.less']
})
export class BrandsComponent implements OnInit {
    isBorwser: boolean;
    public testBrowser: boolean;
    public data: any;
    constructor(private http: HttpClient, @Inject(PLATFORM_ID) platformId: string, public service: SharedService,
                private modalService: NgbModal) {
        this.testBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        this.onSearch();
    }

    onSearch() {
        this.service.getBrandList();
    }

    onOpenBrandDetails(brand) {
        console.log(brand)
        this.service.choosedBrand = brand;
        document.getElementById('btnOpenBrandDetail').click();
    }
}
