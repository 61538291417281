import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SharedService} from "../../services/shared.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {isPlatformBrowser} from "@angular/common";

@Component({
    selector: 'app-blogs',
    templateUrl: './blogs.component.html',
    styleUrls: ['./blogs.component.less']
})
export class BlogsComponent implements OnInit {

    isBorwser: boolean;
    public testBrowser: boolean;
    public data: any;

    constructor(private http: HttpClient, @Inject(PLATFORM_ID) platformId: string, public service: SharedService,
                private modalService: NgbModal) {
        this.testBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        this.service.contentText = null;
        this.service.getBlogList();
    }

}
