<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">{{service.getResource("home")}}</a></li>
                    <li class="breadcrumb-item"><a href="/kataloglar">{{service.getResource("title_models")}}</a></li>
                    <li class="breadcrumb-item">
                        <a><span>{{service.catalogName}}</span>&nbsp;<span>{{service.getResource("models")}}</span></a>
                    </li>
                </ol>
            </nav>
        </div>
        <div style="height: calc(100vh - 91px)" *ngIf="service.productList?.length==0"></div>

        <div class="col-lg-3"></div>
        <div class="col-lg-9 text-center">
            <p style="text-align: center" [innerHTML]="service.contentText"></p>
        </div>
        <div class="col-lg-2">
            <div class="row">
                <div class="col-lg-12" *ngIf="service.brands?.length>0">
                    <div class="fltrs-wrppr">
                        <h6>{{service.getResource("brands")}}</h6>
                        <input type="text" [(ngModel)]="searchBrand" class="form-control"
                               name="searchBrand"
                               placeholder="{{service.getResource('search_brand')}}">

                        <div class="filter-ul">
                            <div *ngFor="let brand of service.brands | filter: 'BrandName': searchBrand">
                                <input type="checkbox" [(ngModel)]="brand.IsSelected"
                                       name="brand{{brand.BrandId}}">&nbsp;&nbsp;{{brand.BrandName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12" style="margin-top: 30px" *ngIf="service.catalogs?.length>0">
                    <div class="fltrs-wrppr">
                        <h6>{{service.getResource("catalogs")}}</h6>
                        <input type="text" [(ngModel)]="searchCatalog" class="form-control"
                               name="searchCatalog"
                               placeholder="{{service.getResource('search_catalog')}}">
                        <div class="filter-ul">
                            <div *ngFor="let cat of service.catalogs| filter: 'CatalogName': searchCatalog">
                                <input type="checkbox" [(ngModel)]="cat.IsSelected"
                                       name="brand{{cat.CatalogId}}">&nbsp;&nbsp;{{cat.CatalogName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="service.properties?.length>0">
                    <div class="col-lg-12" *ngFor="let prop of service.properties;let i = index" style="margin-top: 30px">
                        <div class="fltrs-wrppr">
                            <h6>{{prop.PropertyName}}</h6>
                            <input type="text" [(ngModel)]="prop.search" class="form-control"
                                   name="search{{i}}"
                                   placeholder="{{prop.PropertyName + ' ' + service.getResource('search')}}">

                            <div class="filter-ul">
                                <div *ngFor="let selection of prop.Selections | filter: 'SelectionName': prop.search">
                                    <input type="checkbox" [(ngModel)]="selection.IsSelected"
                                           name="brand{{selection.SelectionId}}">&nbsp;&nbsp;{{selection.SelectionName}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="service.productList.length>0">
                    <div class="form-group">
                        <button type="submit" id="tm-newsletter-popup-button" class="btn newsletter-popup-btn"
                                (click)="onSearch()" style="width: 100%">
                            <span name="btnSearch">{{service.getResource("search")}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-10">
            <p-virtualScroller [value]="service.productList" scrollHeight="100vh" [lazy]="true"
                               [itemSize]="100" [rows]="service.productList.length/3"
                               (onLazyLoad)="loadCarsLazy($event)">
                <ng-template let-product pTemplate="item" let-i="index">
                    <div class="product-thumb transition options" *ngIf="product.CatalogTitleName==null"
                         style="float: left; width: 285px; height: 304px;margin-left: 5px!important;">
                        <div class="image">
                            <a style="cursor:pointer"
                               href="/model-detayi/{{product.CatalogURLName}}/{{product.UrlName}}">
                                <div class="brown-background"></div>
                                <img alt="{{product.ProductName}}" title="{{product.ProductName}}"
                                     [customObservable]="scroll$"
                                     class="img-responsive"
                                     [defaultImage]="product.Files[0].FileURL"
                                     [lazyLoad]="product.Files[0].FileURL"
                                     id="img" *ngIf="product.Files!=null" style="width:270px;height:270px">
                            </a>
                            <a class="quickview" data-toggle="tooltip" title="" data-rel="details"
                               href="/model-detayi/{{product.CatalogURLName}}/{{product.UrlName}}"
                               data-original-title="Quick View">
                                <div style="display: table-cell">
                                    <i class="fa fa-eye"></i></div>
                            </a>
                        </div>
                        <div class="caption">
                            <div class="name ng-binding">
                                <b>{{product.CatalogName}}</b> / {{product.ProductName}}
                            </div>
                        </div>
                    </div>
                </ng-template>

            </p-virtualScroller>
        </div>
    </div>
</div>
