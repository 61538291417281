import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class filter implements PipeTransform {
  transform(items: any[], field: string, value: string): any[] {
    if (!items) {
      return [];
    }
    if (!value || value.length == 0) {
      return items;
    }
    console.log(items);
    return items.filter(it =>
      it[field].toString().toLowerCase().indexOf(value.toLowerCase()) != -1);
  }
}
