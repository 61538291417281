<app-mdl-notice></app-mdl-notice>
<div style="height: calc(100vh - 91px)" *ngIf="service.sliderImages?.length==0"></div>
<ngb-carousel *ngIf="service.sliderImages">
    <ng-template ngbSlide style="height: calc(100vh - 91px)" *ngFor="let image of service.sliderImages">
        <img [src]="image.FileName" alt="{{image.Description}}" style="height: calc(100vh - 91px);width: 100%">
    </ng-template>
</ngb-carousel>
<div class="btn-over-slider" id="catalogBtn">
            <span><span>{{service.getResource("look_catalog_1")}}</span><br>
                <span>{{service.getResource("look_catalog_2")}}</span>
            </span><br>
    <a class="btn btn-over-slider-btn" routerLink="/kataloglar">{{service.getResource("catalogs")}}</a>
</div>
