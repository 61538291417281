import {BrowserModule, TransferState, makeStateKey, StateKey} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {HttpConfigInterceptor} from './http/interceptor/httpconfig.interceptor';
import {Api} from './http/Api';
import {ServiceConnectionsService} from './services/service-connections.service';
import {AppRoutingModule} from './app-routing.module';
import {ServicesModuleModule} from './services-module.module';
import {MaterialModule} from './material-module.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {BrowserTransferStateModule} from '@angular/platform-browser';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {HomeComponent} from './pages/home/home.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MenuComponent} from './pages/menu/menu.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CatalogsComponent} from './pages/catalogs/catalogs.component';
import {BrandsComponent} from './pages/brands/brands.component';
import {MdlBrandDetailsComponent} from './pages/mdl-brand-details/mdl-brand-details.component';
import {MdlNoticeComponent} from "./pages/mdl-notice/mdl-notice.component";
import {BlogsComponent} from './pages/blogs/blogs.component';
import {BlogDetailsComponent} from './pages/blog-details/blog-details.component';
import {ProductListComponent} from './pages/product-list/product-list.component';
import {ProductDetailsComponent} from './pages/product-details/product-details.component';
import {ContactsComponent} from './pages/contacts/contacts.component';
import {NgImageSliderModule} from "ng-image-slider";
import {TransferHttpCacheModule} from '@nguniversal/common';
import {TranslatePipeErhanPipe} from './pages/pipes/translate-pipe-erhan.pipe';
import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks} from "ng-lazyload-image";
import {VirtualScrollerModule} from 'primeng/virtualscroller';

import {filter} from "./services/filter/filter.component";

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        MenuComponent,
        CatalogsComponent,
        BrandsComponent,
        MdlNoticeComponent,
        MdlBrandDetailsComponent,
        BlogsComponent,
        BlogDetailsComponent,
        ProductListComponent,
        ProductDetailsComponent,
        ContactsComponent,
        TranslatePipeErhanPipe,
        filter
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'dervisogluDynamicV2'}),
        TransferHttpCacheModule,
        AppRoutingModule,
        HttpClientModule,
        ServicesModuleModule,
        BrowserAnimationsModule,
        MaterialModule,
        FormsModule,
        NgbModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ReactiveFormsModule,
        NgImageSliderModule,
        LazyLoadImageModule,
        VirtualScrollerModule
    ],
    providers: [Api,
        {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
        {provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
