import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ServiceConnectionsService} from '../../services/service-connections.service';
import {main} from '@angular/compiler-cli/src/main';
import {SharedService} from '../../services/shared.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {

    constructor(
        private translation: TranslateService, public serviceConnection: ServiceConnectionsService, public service: SharedService) {
    }

    cultureName = null;

    ngOnInit(): void {
        this.cultureName = 'TR';
        if (this.serviceConnection.getHandleStorageData('language') == 'en-US') {
            this.cultureName = 'EN';
        }
    }

    onChangeLanguage(cultureName, shortName) {
        this.translation.setDefaultLang(cultureName);
        this.serviceConnection.setHandleStorageData('language', cultureName);
        this.cultureName = shortName;
        window.location.reload();
    }

    onCloseMenu() {
        document.getElementById('catalogBtn').style.display = 'block';
        document.getElementById('navbarNavDropdown').className = 'collapse navbar-collapse';
    }

    onCloseCatalog() {
        if (document.getElementById('catalogBtn').style.display == 'block' || document.getElementById('catalogBtn').style.display == '') {
            document.getElementById('catalogBtn').style.display = 'none';
        }else{
            document.getElementById('catalogBtn').style.display = 'block';
        }
    }
}
