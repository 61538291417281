import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SharedService} from '../../services/shared.service';
import {isPlatformBrowser} from '@angular/common';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-catalogs',
    templateUrl: './catalogs.component.html',
    styleUrls: ['./catalogs.component.less']
})
export class CatalogsComponent implements OnInit {

    isBorwser: boolean;
    public testBrowser: boolean;
    public data: any;
    frmSearchProduct = {
        ProductGroupId: null,
        BrandId: null,
        BrandUrlName: null,
        SearchText: null
    };
    frmOrderIndex = 10;

    constructor(private http: HttpClient, @Inject(PLATFORM_ID) platformId: string, public service: SharedService, private router: ActivatedRoute) {
        this.testBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        this.service.contentText = null;
        this.frmSearchProduct.BrandUrlName = this.router.snapshot.params.brandUrlName;
        this.onSearch();
    }

    onCmbChanged() {
        this.frmSearchProduct.BrandUrlName = null;
        this.onSearch();
    }

    setValue(pg) {
        this.frmSearchProduct.BrandId = pg.BrandId;
    }

    onSearch() {
        this.service.getCatalogList(this.frmSearchProduct);
    }

    onChangeProp() {
        let type = 1;
        if (this.frmOrderIndex == 10) {
            type = 1;
        } else {
            type = -1;
        }

        this.sortBy('OrderIndex', type);
    }

    sortBy(prop: string, type) {
        return this.service.catalogs.sort((a, b) => a[prop] > b[prop] ? (type) * 1 : a[prop] === b[prop] ? 0 : (type) * (-1));
    }
}
