import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SharedService} from "../../services/shared.service";
import {ActivatedRoute} from "@angular/router";
import {isPlatformBrowser} from "@angular/common";

@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.less']
})
export class ProductDetailsComponent implements OnInit {

    constructor(private http: HttpClient, @Inject(PLATFORM_ID) platformId: string, public service: SharedService, private router: ActivatedRoute) {
        this.testBrowser = isPlatformBrowser(platformId);
    }

    isBorwser: boolean;
    public testBrowser: boolean;
    public data: any;
    productUrlName = null;
    catalogUrlName = null;
    formComment = {
        ProductId: null,
        Title: null,
        PhoneNumber: null,
        Email: null,
        Comment: null
    };

    submitted = false;

    ngOnInit(): void {
        this.service.contentText = null;
        this.service.addedCommentSuccessFully = false;
        this.productUrlName = this.router.snapshot.params.productUrlName;
        this.catalogUrlName = this.router.snapshot.params.catalogUrlName;
        const p = {
            productUrlName: this.productUrlName,
            catalogUrlName: this.catalogUrlName
        };
        this.service.getProductDetails(p);
    }

    onOpenImage(image) {
        // @ts-ignore
        window.location = '/model-detayi/' + this.service.relatedProduct[image].CatalogUrlName + '/' + this.service.relatedProduct[image].ProductUrlName;
    }

    updateRate() {
        const p = {
            ProductId: this.service.productDetails.ProductId,
            Rate: this.service.productDetails.AverageRate
        };
        this.service.addProductRate(p);
    }

    setComment() {
        this.submitted = true;
        this.formComment.ProductId = this.service.productDetails.ProductId;
        if (this.formComment.Title != null && this.formComment.Title != "" &&
            this.formComment.PhoneNumber != null && this.formComment.PhoneNumber != "" &&
            this.formComment.Email != null && this.formComment.Email != "" &&
            this.formComment.Comment != null && this.formComment.Comment != "") {
            this.service.addProductComment(this.formComment);
        }
    }

}
